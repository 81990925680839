html,
body {
  height: 100%;
  min-height: 100%;
  max-height: 100vh;
}

#root {
  height: 100%;
}

.mainNavBox {
  position: relative;
  z-index: 999;
}

.welcomeBox {
  width: fit-content;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
}

.mainContainer {
  margin-top: 20px;
  height: calc(100% - 90px);
  box-sizing: border-box;
}

@media (max-height: 700px) {
  .maingrid--flex {
    height: 100% !important;
  }
}

.mainSubtitle {
  margin-bottom: 10px !important;
}

.googleLoginIcon {
  margin-right: 10px !important;
}

.maingrid--flex {
  display: flex;
  height: 97%;
}

.maingrid--itm {
  width: 100%;
  height: 100%;
}

.maingrid--left {
  flex-shrink: 3;
}

@media (max-width: 1150px) {
  .maingrid--left {
    flex-shrink: 2;
  }
}

@media (max-width: 600px) {
  .maingrid--left {
    flex-shrink: 1;
  }
}

.maingrid--right {
  overflow-y: auto;
  overflow-x: hidden;
}

.filterPaper {
  margin-right: 30px;
  padding: 30px;
  box-sizing: border-box;
  margin-left: 3px;
  margin-bottom: 3px;
  margin-top: 3px;
  overflow-y: auto;
  max-height: 100%;
}

.regularDivider {
  margin-bottom: 10px !important;
}

.bottomDivider {
  margin-top: 20px !important;
}

.centerPagination {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  width: fit-content;
}

.centerButton {
  display: inline;
  text-align: center;
}

.studentInfoContainer {
  margin-left: 2px;
  margin-right: 2px;
}

.filterTopMargin {
  margin-top: 7px !important;
}

@media (max-width: 450px) {
  .maingrid--flex {
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: unset;
  }
  .maingrid--itm {
    height: unset;
  }
  .maingrid--right {
    height: 0px !important;

    overflow-x: unset;
    overflow-y: unset;
    margin-top: 10px;
  }
  .maingrid--left {
    flex-shrink: unset;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .mainContainer {
    margin-top: 20px;
  }

  .filterPaper {
    width: 100%;
    margin-bottom: 20px;
    height: unset;
    overflow-y: unset;
  }

  .userCard {
    margin-right: 0px !important;
    margin-bottom: 12px !important;
  }

  .filterParts {
    display: none;
  }

  .studentInfoText {
    display: none;
  }
}

.navImg {
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.tagsDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  height: fit-content;
  width: fit-content;
}

.lineGraphSelect {
  height: 10px;
}
.smallMenu {
  position: absolute;
  z-index: 99;
  top: 5px;
  right: 5px;
}

.centerText {
  align-self: center;
}

.userCard {
  position: relative;
}
.dialogActionButtonsDiv {
  display: flex;
  flex-wrap: wrap;
}

.dialogActionButtons {
  margin-right: 5px !important;
  width: 49% !important;
  margin-bottom: 5px !important;
}

.modalDivider {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.parentPhoneContainer {
  margin-left: 0px !important;
  width: 100%;
}

.cardButtons {
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}

.filterOptionsHead {
  display: flex;
  margin-bottom: 5px;
  height: 37px;
  align-items: center;
}

.resetBtnFilter {
  margin-left: auto !important;
}

.cardHeaderActions {
  position: sticky;
  top: 0;
  background-color: white; /* Ensure the background color matches the card */
  z-index: 3; /* Ensure it stays on top of other content */
  padding: 10px; /* Optional: Add some padding for better appearance */
}

.TagsTop {
  position: absolute;
  z-index: 2;
  top: 13px;
  left: 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
}

.inline {
  display: inline;
}

.expectationsDiv {
  padding: 5px;
  border-radius: 5px;
}

.expectationsMet {
  background-color: #4caf50;
  color: white;
}

.expectationsBelow {
  background-color: #f44336;
  color: white;
}

.expectationsScore {
  display: inline;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  font-size: 0.6rem;
  vertical-align: 14%;
}

@media print {
  .noPrint {
    display: none !important;
  }

  body {
    margin: 1mm 1mm 1mm 1mm;
  }

  .dataTable {
    overflow-y: visible !important;
  }
}
